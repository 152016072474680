import { useEffect, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AOS from 'aos';
import NavScrollTop from './components/NavScrollTop';
const HomeOne = lazy(() => import('./pages/HomeOne'));
const About = lazy(() => import('./pages/About'));
const Service = lazy(() => import('./pages/Service'));
const Work = lazy(() => import('./pages/Work'));
const Referances = lazy(() => import('./pages/Referances'));
const Contact = lazy(() => import('./pages/Contact'));
const TeklifAl = lazy(() => import('./pages/TeklifAl'));
const HizmetlerDetails = lazy(() => import('./pages/HizmetDetails'));

function App() {
  useEffect(() => {
    AOS.init({
      offset: 80,
      duration: 1000,
      once: true,
      easing: 'ease',
    });
    AOS.refresh();
  }, []);
  return (
    <Router>
      <NavScrollTop>
        <Suspense fallback={<div />}>
          <Routes>
            <Route
              path={`${process.env.PUBLIC_URL + '/'}`}
              element={<HomeOne />}
            />
            <Route
              path={`${process.env.PUBLIC_URL + '/hakkimizda'}`}
              element={<About />}
            />
            {/* <Route path={`${process.env.PUBLIC_URL + "/referans"}`} element={<Referances/>} /> */}
            <Route
              path={`${process.env.PUBLIC_URL + '/hizmet'}`}
              element={<Service />}
            />
            <Route
              path={`${process.env.PUBLIC_URL + '/hizmet/:slug'}`}
              element={<HizmetlerDetails />}
            />
            {/* <Route path={`${process.env.PUBLIC_URL + "/portfolyo"}`} element={<Work/>} /> */}
            <Route
              path={`${process.env.PUBLIC_URL + '/iletisim'}`}
              element={<Contact />}
            />
            {/* <Route path={`${process.env.PUBLIC_URL + "/teklif-al"}`} element={<TeklifAl/>} /> */}
          </Routes>
        </Suspense>
      </NavScrollTop>
    </Router>
  );
}

export default App;
